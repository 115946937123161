















import { Component, Vue, Prop } from 'vue-property-decorator'

import CodeInputStep from '@/components/Modals/Default/TwoFactorAuth/components/CodeInputStep.vue'

@Component({
  name: 'TheSecondFactorDiscardStep',
  components: {
    CodeInputStep,
  },
})
export default class TheSecondFactorDiscardStep extends Vue {
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean
  @Prop({ type: Number, default: 4 }) readonly length!: number
  @Prop({ type: String, default: '' }) readonly error!: string
  @Prop({ type: Number, default: -1 }) readonly timeout!: number
  @Prop({ type: String, default: '' }) readonly emailMask!: string

  get label () {
    const email = this.emailMask || this.$t('auth.newLogin.emaily')
    return this.$t('auth.newLogin.onEmailCodeSent', {
      email,
    }).toString()
  }

  onInputEnd (event: InputEvent) {
    this.$emit('input', event)
    this.$emit('update:error', '')
  }

  onResendCodeClick () {
    this.$emit('resend')
    window.goal('loginAction', {
      login: '2fa: кнопка "Отправить еще раз" код сброса',
    })
  }
}
