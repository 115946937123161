import { render, staticRenderFns } from "./TheSecondFactorDiscardStep.vue?vue&type=template&id=172207ad&"
import script from "./TheSecondFactorDiscardStep.vue?vue&type=script&lang=ts&"
export * from "./TheSecondFactorDiscardStep.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCardSection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCardSection})
