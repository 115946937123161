



































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { QInput } from 'quasar'

@Component({
  name: 'CodeInputStep',
})
export default class CodeInputStep extends Vue {
  @Prop({ type: Boolean, required: true }) readonly loading!: boolean
  @Prop({ type: String, required: true }) readonly label!: string
  @Prop({ type: Number, default: 4 }) readonly length!: 4 | 5 | 6
  @Prop({ type: String, default: '' }) readonly err!: string
  @Prop({ type: Number, default: -1 }) readonly timeout!: number

  /**
   * Uses for displaying result after inputting all chars
   * in some cases we need to change inputs - use FALSE for it
   * * IF you need to provide user change the value - use FALSE
   * * ELSE - default TRUE to display shadow value while loading
   * * and empty inputs after load end (for errors)
   */
  @Prop({ type: Boolean, default: true }) readonly shadowDisplay!: boolean

  $refs!: {
    /**
     * @length 4 | 5 | 6
     */
    code: [QInput]
  }

  canShowErr = true

  inputs: Array<string> = []
  prev: Array<string> = []

  created () {
    for (let index = 0; index < 6; index++) {
      this.inputs.push('')
      this.prev.push('')
    }
  }

  /**
   * Display preview means that u don't need to emit event
   * and u need to show numbers without focusing
   * for example using with button controller
   */
  get previewArray () {
    const arr = this.loading && this.shadowDisplay ? this.prev : this.inputs
    return arr.slice(0, this.length)
  }

  // return value of timeout in MM:SS format
  get codeTimout () {
    let minutes: number | string = Math.floor(this.timeout / 60)
    let seconds: number | string = Math.floor(this.timeout % 60)
    if (minutes < 10) minutes = '0' + minutes
    if (seconds < 10) seconds = '0' + seconds
    return minutes + ':' + seconds
  }

  // show error and background for inputs only if ERROR exists
  // and user doesn't start inputting
  get isErrActive (): boolean {
    return this.canShowErr && !!this.err && !this.loading
  }

  /**
   * Focus on fisrt input for improving parent-child connections
   */
  public async focus () {
    await this.$nextTick()
    this.$refs.code[0].focus()
  }

  /**
   * Control method for inputs
   * @emits input-end with code from inputs
   */
  inputChar (value: string, index: number) {
    // paste from those index to the end of value or codeLength values char by char
    const len = Math.min(index + value.length + 1, this.length)
    let tmp = 0
    for (let i = index; i < len; i++) {
      this.$set(this.inputs, i, value.charAt(tmp) ?? '')
      tmp++
    }
    this.canShowErr = false

    const tempArr = this.inputs.slice(0, this.length)

    if (tempArr.every(e => e.length >= 1)) {
      this.prev = [...this.inputs]
      this.$emit('input-end', tempArr.join(''))
      const ref = this.$refs.code[index]
      ref.blur()

      this.canShowErr = true // because only before start inputs we can show error

      // usually after inputting we do action and must reset all inputs,
      // but in some cases we must show user it's inputs for changing or something like that
      if (!this.shadowDisplay) return

      for (let index = 0; index < this.inputs.length; index++) {
        this.inputs[index] = ''
      }
    } else if (index < this.length - 1 && value !== '') {
      const ref = this.$refs.code[len - 1] // move focus to next input after inputted length
      ref.focus()
    }
  }

  // move focus to prev input after removing char
  removeChar (index: number) {
    const ref = this.$refs.code[--index]
    ref && ref.focus()
  }

  /**
   * Ask for resending code
   * @emits resend-code ask for resend code
   */
  sendCode () {
    for (let index = 0; index < this.prev.length; index++) {
      this.prev[index] = ''
      this.inputs[index] = ''
    }
    this.canShowErr = true
    this.$emit('resend-code')
  }
}
